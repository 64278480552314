import http from '../../service/http/http';

const state = {
  leiloesDestaque: [],
  leiloesAbertos: [],
  leiloesFechados: [],
  leiloesEmbreve: [],
  leilaoLoading: {
    destaque: false,
    aberto: false,
    breve: false,
    fechado: false,
    verificacao: false,
  },
  leilao: '',
  leilaoHabilitado: '',
  leilaoErro: null,
  solicitacaoAcesso: '',
  reenviaCodigoLoading: '',
  isNextPage: '',
};

const mutations = {
  SET_LEILOES_DESTAQUE: (state, value) => (value ? (state.leiloesDestaque = value) : (state.leiloesDestaque = [])),
  SET_LEILOES_ABERTO: (state, value) => (value ? state.leiloesAbertos.push(...value) : (state.leiloesAbertos = [])),
  SET_LEILOES_FECHADO: (state, value) => (value ? state.leiloesFechados.push(...value) : (state.leiloesFechados = [])),
  SET_LEILOES_BREVE: (state, value) => (value ? state.leiloesEmbreve.push(...value) : (state.leiloesEmbreve = [])),
  SET_LEILAO_DESTAQUE_LOADING: (state, value) => (value ? (state.leilaoLoading.destaque = value) : (state.leilaoLoading.destaque = false)),
  SET_LEILAO_ABERTO_LOADING: (state, value) => (value ? (state.leilaoLoading.aberto = value) : (state.leilaoLoading.aberto = false)),
  SET_LEILAO_BREVE_LOADING: (state, value) => (value ? (state.leilaoLoading.breve = value) : (state.leilaoLoading.breve = false)),
  SET_LEILAO_FECHADO_LOADING: (state, value) => (value ? (state.leilaoLoading.fechado = value) : (state.leilaoLoading.fechado = false)),
  SET_LEILAO_VERIFICACAO_LOADING: (state, value) => (value ? (state.leilaoLoading.verificacao = value) : (state.leilaoLoading.verificacao = false)),
  SET_LEILAO: (state, value) => (value ? (state.leilao = value) : (state.leilao = '')),
  SET_LEILAO_HABILITADO: (state, value) => (value ? (state.leilaoHabilitado = value) : (state.leilaoHabilitado = '')),
  SET_LEILAO_ERRO: (state, value) => (value ? (state.leilaoErro = value) : (state.leilaoErro = null)),
  SET_SOLICITACAO_ACESSO: (state, value) => (value ? (state.solicitacaoAcesso = value) : (state.solicitacaoAcesso = '')),
  SET_REENVIA_LOADING: (state, value) => (value ? (state.reenviaCodigoLoading = value) : (state.reenviaCodigoLoading = '')),
  IS_NEXT_PAGE: (state, value) => (value ? (state.isNextPage = value) : (state.isNextPage = '')),
};

const getters = {
  getLeiloesDestaque(state) {
    return state.leiloesDestaque;
  },
  getLeiloesAberto(state) {
    return state.leiloesAbertos;
  },
  getLeiloesFechado(state) {
    return state.leiloesFechados;
  },
  getLeiloesBreve(state) {
    return state.leiloesEmbreve;
  },
  getLeiloesDestaqueLoading(state) {
    return state.leilaoLoading.destaque;
  },
  getLeiloesAbertoLoading(state) {
    return state.leilaoLoading.aberto;
  },
  getLeiloesBreveLoading(state) {
    return state.leilaoLoading.breve;
  },
  getLeiloesFechadoLoading(state) {
    return state.leilaoLoading.fechado;
  },
  getLeiloesVerificacaoLoading(state) {
    return state.leilaoLoading.verificacao;
  },
  getLeilao(state) {
    return state.leilao;
  },
  getLeilaoHabilitado(state) {
    return state.leilaoHabilitado;
  },
  getLeilaoErro(state) {
    return state.leilaoErro;
  },
  getSolicitacaoAcesso(state) {
    return state.solicitacaoAcesso;
  },
  getReenviaCodigoLoading(state) {
    return state.reenviaCodigoLoading;
  },
  getIsNextPage(state) {
    return state.isNextPage;
  },
};

const actions = {
  async setLeiloesDestaque({ commit }) {
    return new Promise((resolve, reject) => {
      commit('SET_LEILAO_DESTAQUE_LOADING', true);
      return http({
        url: `leilao/destaques`,
        method: 'get',
      })
        .then((resp) => {
          commit('SET_LEILOES_DESTAQUE', resp.data);
          commit('SET_LEILAO_DESTAQUE_LOADING');
          resolve();
        })
        .catch(() => {
          commit('SET_LEILAO_DESTAQUE_LOADING');
          reject();
        });
    });
  },
  async setLeiloesPorStatus({ commit }, value) {
    // let status = value.status && value.status.toUpperCase()
    return new Promise((resolve, reject) => {
      let status = value.status && value.status.toUpperCase();
      commit('SET_LEILAO_' + status + '_LOADING', true);
      return http({
        url: `leilao?pageSize=${value.pageSize}&pageNumber=${value.pageNumber}&status=${value.status}`,
        method: 'get',
      })
        .then((resp) => {
          commit('SET_LEILOES_' + status, resp.data?.data);
          commit('IS_NEXT_PAGE', resp.data.nextPage);
          commit('SET_LEILAO_' + status + '_LOADING');
          resolve();
        })
        .catch(() => {
          commit('SET_LEILAO_' + status + '_LOADING');
          reject();
        });
    });
  },

  async setLeilao({ commit, state }, value) {
    if (state.leilao && state.leilao.id == value) return;
    return http({
      url: `leilao/${value}`,
      method: 'get',
    })
      .then((resp) => {
        commit('SET_LEILAO', resp.data.data);
      })
      .catch(() => commit('SET_LEILAO'));
  },

  async verificarAcesso({ commit }, value) {
    commit('SET_LEILAO_VERIFICACAO_LOADING', true);
    return http({
      url: `Leilao/${value}/verificar-acesso`,
      method: 'get',
    })
      .then((resp) => {
        commit('SET_LEILAO_HABILITADO', resp.data.data);
        commit('SET_LEILAO_VERIFICACAO_LOADING');
      })
      .catch(() => commit('SET_LEILAO_VERIFICACAO_LOADING'));
  },

  async solicitaAcesso({ commit }, value) {
    commit('SET_LEILAO_VERIFICACAO_LOADING', true);
    const payload = {
      regraHabilitacaoId: value.regraHabilitacaoId,
      para: value.para,
      aceiteTermo: true,
    };
    return http({
      url: `Leilao/${value.leilaoId}/solicitar-acesso`,
      method: 'post',
      data: payload,
    })
      .then((resp) => {
        //console.log(resp);
        commit('SET_SOLICITACAO_ACESSO', resp.data.data);
        commit('SET_LEILAO_VERIFICACAO_LOADING');
        commit('SET_LEILAO_ERRO');
      })
      .catch(() => {
        //console.log(err);
        commit('SET_LEILAO_VERIFICACAO_LOADING');
        commit('SET_LEILAO_ERRO', { mensagem: 'Erro ao enviar o código' });
      });
  },
  async validaAcesso({ commit }, value) {
    commit('SET_LEILAO_VERIFICACAO_LOADING', true);
    const payload = {
      solicitacaoId: value.solicitacaoId,
      codigo: value.codigo,
    };
    return http({
      url: `Leilao/${value.leilaoId}/validar-acesso`,
      method: 'get',
      params: payload,
    })
      .then(() => {
        //console.log(resp);
        commit('SET_LEILAO_VERIFICACAO_LOADING');
        commit('SET_LEILAO_ERRO');
      })
      .catch(() => {
        //console.log(err);
        commit('SET_LEILAO_VERIFICACAO_LOADING');
        commit('SET_LEILAO_ERRO', { mensagem: 'Código inválido' });
      });
  },

  async reenviaCodigo({ commit }, value) {
    commit('SET_REENVIA_LOADING', true);
    const payload = {
      to: value.to,
      channel: value.channel,
    };
    return http({
      url: `Verificacao/Enviar-Codigo`,
      method: 'post',
      data: payload,
    })
      .then(() => {
        //console.log(resp);
        commit('SET_REENVIA_LOADING');
        commit('SET_LEILAO_ERRO');
      })
      .catch(() => {
        //console.log(err);
        commit('SET_REENVIA_LOADING');
        commit('SET_LEILAO_ERRO', { mensagem: 'Código inválido' });
      });
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};

<template>
  <div>
    <div class="pt-5 d-none d-md-block">
      <div class="container banner">
        <div class="row justify-content-md-center">
          <div class="banner-leilao col-lg-1" v-if="getLeilao">
            <a :href="'/lotes/' + getLeilao.id"><img :src="getLeilao.foto.url" :title="getLeilao.nome" class="foto-leilao" /></a>
          </div>
          <div class="banner-info col-lg-7" v-if="getLeilao">
            <div class="info-banner">
              <h6>{{ getLeilao.nome }}</h6>

              <h6
                v-if="
                  getLeilao.statusSite === 'aberto' &&
                  (getLeilao.status === 'Aberto' || getLeilao.status === 'Agendado') &&
                  getLeilao.tipo != 'VENDA DIRETA'
                "
              >
                ABERTO PARA LANCES
              </h6>
              <h6
                v-if="
                  getLeilao.statusSite === 'aberto' &&
                  (getLeilao.status === 'Aberto' || getLeilao.status === 'Agendado') &&
                  getLeilao.tipo == 'VENDA DIRETA'
                "
              >
                ABERTO
              </h6>
              <h6 v-else-if="getLeilao.statusSite === 'aberto' && (getLeilao.status === 'Em Andamento' || getLeilao.status === 'Em Pregão')">
                EM PREGÃO
              </h6>
              <h6 v-else-if="getLeilao.statusSite === 'fechado' && getLeilao.status === 'Cancelado'">CANCELADO</h6>
              <h6 v-else-if="getLeilao.statusSite === 'fechado' || getLeilao.status === 'Encerrado'">ENCERRADO</h6>
              <h6 v-else-if="getLeilao.statusSite === 'breve'">EM BREVE</h6>
            </div>
            <div class="buttons mt-3" v-if="getLeilao.anexos.length > 0">
              <ul class="lista-botoes">
                <li v-for="anexo in getLeilao.anexos" :key="anexo.ArquivoId">
                  <a class="edital-button" :href="anexo.arquivo.url" target="_blank">{{ anexo.nome }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="product-details padding-bottom mt-240 mt-lg--440">
      <div class="container">
        <div class="d-flex align-items-center mb-2 justify-content-center justify-content-lg-between flex-wrap">
          <div class="d-flex flex-column pl-0 flex-wrap justify-content-center justify-content-lg-start">
            <h6 class="numeroLote align-self-center align-self-lg-start text-muted mb-3 mt-2 d-block d-lg-none">
              <a :href="'/lotes/' + getLeilao.id">{{ getLeilao.nome }}</a>
            </h6>
            <h6 class="numeroLote align-self-center align-self-lg-start">LOTE {{ getLote.numeroLote }}</h6>
            <p v-if="getLote.tipoLote" class="m-0 align-self-center align-self-lg-start">
              <span>{{ getLote.tipoLote.descricao }}</span>
            </p>
            <!-- <a
              class="custom-button edital-button col-12 col-sm-12 col-md-12 col-xl-5 text-center"
              target="_blank"
              >LOTE DESTAQUE</a
            > -->
          </div>
          <div class="row">
            <div class="col-12">
              <div class="nav-lote">
                <button
                  class="btn btn-sm btn-link btn-navlote"
                  title="Lote Anterior"
                  :disabled="getLote.anteriorLoteId === 0"
                  @click="navLote(getLote.anteriorLoteId)"
                >
                  <img :src="require('@/assets/images/arrow_left.png')" /> Lote Anterior
                </button>
                <select class="select-numero-lote" v-model="loteIdSelecionado" @change="navLote(null)">
                  <option v-for="loteNumero in getNumerosLotes" :key="loteNumero.loteId" :value="loteNumero.loteId">
                    Lote {{ loteNumero.numeroLote }}
                  </option>
                </select>

                <button
                  class="btn btn-sm btn-link btn-navlote"
                  title="Próximo Lote"
                  :disabled="getLote.proximoLoteId === 0"
                  @click="navLote(getLote.proximoLoteId)"
                >
                  Próximo Lote
                  <img :src="require('@/assets/images/arrow_right.png')" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <slide v-once />

        <detalhes @atualizaHistorico="atualizaHistorico" :loteId="loteId" />
      </div>
      <abas @mais="historicoLances" @aba="aba" />
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';

import Abas from './components/Abas.vue';
import Detalhes from './components/Detalhes.vue';
import Slide from './components/Slide.vue';

export default {
  props: {
    loteId: {
      type: String,
    },
  },
  data() {
    return {
      nomeLeilao: '',
      leilaoId: 0,
      lote: '',
      loteIdSelecionado: '',
      numeroLote: 0,
      abaAtiva: null,
      valor: 0,
      colorPrimary: process.env.VUE_APP_PRIMARY_COLOR,
      colorSecondary: process.env.VUE_APP_SECONDARY_COLOR,
      colorConstrast: process.env.VUE_APP_CONSTRAST_COLOR,
    };
  },
  components: {
    Slide,
    Detalhes,
    Abas,
  },
  computed: {
    ...mapGetters([
      'getLotesLoading',
      'getLote',
      'getLanceError',
      'getHistoricoLances',
      'getLoteInfo',
      'getLoteIdSelecionado',
      'getLeilao',
      'getAuth',
      'getNumerosLotes',
    ]),
  },
  watch: {
    // getLoteInfo() {
    //   const { pageNumber } = this.getHistoricoLances;
    //   this.historicoLances(pageNumber * 10);
    // },
  },
  methods: {
    ...mapMutations(['SET_SIGNAL', 'SET_LOTEID_SELECIONADO']),
    ...mapActions([
      'setLote',
      'setLoteInfo',
      'addPageView',
      'setHistoricoLances',
      'verificarAcesso',
      'setLeilao',
      // "verificarAcessoLeilao",
      'info',
      'setNumerosLotes',
    ]),
    aba(val) {
      this.abaAtiva = val;
      if (val == 'historico') {
        this.atualizaHistorico();
      }
    },
    historicoLances(pageSize, page) {
      let pageNumber = 1;

      if (this.getHistoricoLances.pageNumber) {
        pageNumber = this.getHistoricoLances.pageNumber + 1;
      }

      if (pageNumber > this.getHistoricoLances.totalPages) return;

      this.setHistoricoLances({
        loteId: this.getLoteIdSelecionado,
        pageSize: pageSize ? pageSize : 10,
        pageNumber: page ? page : pageNumber,
      });
    },
    atualizaHistorico() {
      this.setHistoricoLances({
        loteId: this.getLoteIdSelecionado,
        pageSize: 10,
        pageNumber: 1,
      });
    },
    onEventNewLance(loteinfo) {
      if (this.getLote.loteId === loteinfo.loteId) {
        this.$store.commit('SET_LOTE_INFO', loteinfo);
      }
    },
    onJoinGroup(leilaoId) {
      this.$signalR.joinLeilao(leilaoId);
    },
    async navLote(loteId) {
      loteId = loteId || this.loteIdSelecionado;
      this.SET_SIGNAL(true);
      this.SET_LOTEID_SELECIONADO(loteId);
      window.history.pushState('', '', '' + loteId);
      this.$store.commit('SET_LOTE_INFO_LOADING', true);
      this.valor = 0;
      this.loteIdSelecionado = loteId;

      await this.setLote(loteId);
      await this.setLoteInfo(loteId);
    },
    returnNumeroLote(numeroLote) {
      this.numeroLote = numeroLote;
      return numeroLote;
    },
  },
  async created() {
    await this.info();
    this.loteIdSelecionado = this.loteId;
    this.SET_LOTEID_SELECIONADO(this.loteId);
    this.addPageView(this.loteId);
    await this.setLote(this.loteId).then((lote) => this.onJoinGroup(lote.leilaoId));
    //this.setLoteInfo(this.loteId);
    await this.setLeilao(this.getLote.leilaoId);
    await this.setNumerosLotes(this.getLote.leilaoId);

    if (this.getAuth) {
      this.verificarAcesso(this.getLote.leilaoId);
    }
    //this.historicoLances();

    this.$signalR.$on('new-lance', this.onEventNewLance);

    //this.onSignalMesage();
  },
  beforeDestroy() {
    this.$signalR.$off('new-lance', this.onEventNewLance);
  },
};
</script>

<style scoped>
.btn-link:hover {
  text-decoration: none !important;
}
.lista-botoes {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
}
.banner-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  color: v-bind(colorConstrast) !important;
}

.banner-leilao a {
  height: 100%;
  width: 100%;
}
.banner {
  margin-top: 60px !important;
  background-color: v-bind(colorPrimary);
  border-radius: 20px;
  padding: 20px;
}

.foto-leilao {
  width: 100%;
  height: 100%;
  vertical-align: middle;
  object-fit: contain;
}
.product-details {
  margin-top: 20px;
}

.info-banner h6 {
  color: v-bind(colorConstrast) !important;
}
.numeroLote {
  font-size: 1.8rem !important;
}
.border-radius-5 {
  position: relative;
  border-radius: 10px;
  overflow: hidden;

  justify-content: center;
  align-items: center;
  height: 160px;
  max-height: none;
  padding-top: 0px;
  flex-wrap: nowrap;
}
.auction-item-5 {
  min-height: 0px !important;
  width: 58% !important;
}
.nav-lote {
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
  align-items: center;
}

.info-leiloeiro h4 {
  font-size: 0.8rem;
  color: rgb(148, 148, 148);
  font-weight: 500;
}

.banner-leilao {
  text-align: center;
  background: #fff;
  border-radius: 10px;
  padding: 10px;
  height: 94px;
}

.info-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  height: 100%;
}
@media (max-width: 385px) {
  .product-bid-form button.custom-button {
    width: 90%;
  }
}

@media (max-width: 430px) {
  .product-bid-form button.custom-button {
    width: 90%;
  }
}

@media (max-width: 323px) {
  .product-bid-form input {
    width: 150px !important;
  }
}

@media (max-width: 575px) {
  .product-bid-form input {
    width: 200px;
  }

  .product-details {
    margin-top: 166px;
  }
}

@media (max-width: 1000px) {
  .nav-lote {
    padding-top: 20px;
  }
}
@media (max-width: 1199px) {
  .banner {
    height: auto;
  }
}

.btn-navlote {
  width: auto;
  padding: 0;
  font-size: 14px;
  color: #171d1c;
}

.btn-navlote i {
  color: #006ec7;
}

.removido {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  width: 100%;
  border: solid 4px red;
  color: red;
  padding: 5px 2px;
  border-radius: 5px;
  font-weight: bold;
  letter-spacing: 2px;
}

.box-pulse-yellow {
  border: 1px solid #f9c718;
  box-shadow: 0 0 0 0 rgba(249, 199, 24, 1);
  animation: pulse-yellow 2s infinite;
}

@keyframes pulse-yellow {
  0% {
    box-shadow: 0 0 0 0 rgba(249, 199, 24, 0.7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(249, 199, 24, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(249, 199, 24, 0);
  }
}

.box-pulse-red {
  border: 1px solid #e0280f;
  box-shadow: 0 0 0 0 rgba(224, 40, 15, 1);
  animation: pulse-red 1s infinite;
}

@keyframes pulse-red {
  0% {
    box-shadow: 0 0 0 0 rgba(224, 40, 15, 0.7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(224, 40, 15, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(224, 40, 15, 0);
  }
}

.box-pulse10-red {
  border: 1px solid #e0280f;
  box-shadow: 0 0 0 0 rgba(224, 40, 15, 1);
  animation: pulse-red 0.5s infinite;
}

.btn-warning {
  background-color: #f9c718;
}

.select-numero-lote {
  width: auto;
  border-radius: 19px;
  padding: 0;
  margin: 0 10px;
  text-align: center;
  border: none;
  color: #171d1c;
  font-size: 14px;
  font-weight: 600;
}

.small {
  font-size: 10px;
}

.onoffswitch {
  position: relative;
  width: 70px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.onoffswitch-checkbox {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  height: 35px;
  padding: 0;
  line-height: 35px;
  border: 2px solid #e3e3e3;
  border-radius: 35px;
  background-color: #f2f2f2;
  transition: background-color 0.3s ease-in;
}

.onoffswitch-label:before {
  content: '';
  display: block;
  width: 35px;
  height: 35px;
  margin: 0px;
  background: #ffffff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 33px;
  border: 2px solid #e3e3e3;
  border-radius: 35px;
  transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked + .onoffswitch-label {
  background-color: #0783ae;
}

.onoffswitch-checkbox:checked + .onoffswitch-label,
.onoffswitch-checkbox:checked + .onoffswitch-label:before {
  border-color: #0783ae;
}

.onoffswitch-checkbox:checked + .onoffswitch-label:before {
  right: 0px;
}

.product-bid-area {
  position: relative;
}

.msg {
  max-width: 277px;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 0 2px 0px black;
  position: absolute;
  z-index: 99999;
  right: 3px;
  color: #625d5d;
  background: #fff;
  font-size: 10px;
  opacity: 1;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  top: -27px;
  left: 10px;
}

.info {
  font-size: 18px;
  padding-left: 5px;
  color: rgb(183, 182, 192);
  cursor: pointer;
}

input,
select {
  height: 50px;
  padding: 0px 15px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  border: 1px solid #e0e0f1;
  background: transparent;
  font-size: 16px;
}
.edital-button {
  padding: 0 10px;
  border-radius: 30px;
  background-color: v-bind(colorSecondary) !important;
  color: #fff !important;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  width: 175px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}
.border-next-back {
  width: 24px;
  text-align: center;
  border: 2px solid #006ec7;
  border-radius: 50px;
}
</style>

<template>
  <section class="car-auction-section pos-rel oh">
    <div class="container">
      <div class="row" v-if="getLeiloesDestaqueLoading || getLeiloesFechadoLoading">
        <leilao-card-loader classeRaiz="col-12 col-sm-6 col-md-4 col-lg-3" />
        <leilao-card-loader classeRaiz="col-sm-6 col-md-4 col-lg-3 d-none d-sm-block d-md-block d-lg-block" />
        <leilao-card-loader classeRaiz="col-sm-6 col-md-4 col-lg-3 d-none d-sm-none d-md-block d-lg-block" />
        <leilao-card-loader classeRaiz="col-lg-3 d-none d-sm-none d-md-none d-lg-block" />
      </div>

      <!-- <carousel
        v-else
        :per-page="1.2"
        :perPageCustom="[
          [576, 2],
          [768, 3],
          [992, 4],
        ]"
        :navigationEnabled="isMobile ? false : true"
        :mouse-drag="true"
        :touchDrag="true"
        :scrollPerPage="false"
        :paginationEnabled="false"
        navigationNextLabel="<img src='/assets/images/chevron_right.png'>"
        navigationPrevLabel="<img src='/assets/images/chevron_left.png'>"
        class="carousel-leiloes"
      >
        <slide v-for="leilao in getLeiloesDestaque.data" :key="leilao.id">
          <leilao-card classeRaiz="col-12" :leilao="leilao" />
        </slide>
      </carousel> -->
      <div class="row justify-content-center" v-if="!getLeiloesDestaqueLoading && !getLeiloesFechadoLoading">
        <leilao-card v-for="leilao in getLeiloesDestaque.data" :key="leilao.id" classeRaiz="col-10 col-sm-10 col-md-6 col-lg-3" :leilao="leilao" />
        <leilao-card v-for="leilao in getLeiloesFechado" :key="leilao.id" classeRaiz="col-10 col-sm-10 col-md-6 col-lg-3" :leilao="leilao" />
      </div>

      <div class="center d-flex">
        <router-link :to="'/leiloes'">
          <h4 class="title mb-3 mt-5 verTodos">VER TODOS OS LEILÕES</h4>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import LeilaoCard from '@/components/LeilaoCard.vue';
import LeilaoCardLoader from '@/components/LeilaoCardLoader.vue';
// import { Carousel, Slide } from 'vue-carousel';
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
  components: {
    // Carousel,
    // Slide,
    LeilaoCardLoader,
    LeilaoCard,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    ...mapGetters(['getLeiloesDestaque', 'getLeiloesFechado', 'getLeiloesDestaqueLoading', 'getLeiloesFechadoLoading']),
    isMobile() {
      return this.windowWidth < 576;
    },
  },
  methods: {
    ...mapActions(['setLeiloesDestaque', 'setLeiloesPorStatus']),
    ...mapMutations(['SET_LEILOES_FECHADO']),
  },
  async mounted() {
    await this.setLeiloesDestaque();

    if (this.getLeiloesDestaque.data && this.getLeiloesDestaque.data.length < 12) {
      const pageSize = 12 - this.getLeiloesDestaque.data.length;
      this.SET_LEILOES_FECHADO();
      await this.setLeiloesPorStatus({
        pageSize: pageSize,
        pageNumber: 1,
        status: 'fechado',
      });
    }
  },
};
</script>

<style>
.carousel-leiloes .VueCarousel-wrapper {
  padding: 10px 0;
}

.VueCarousel-navigation-button {
  width: 50px;
}

.VueCarousel-slide {
  display: flex;
}

.verTodos {
  color: #171d1c;
  cursor: pointer;
  font-size: 1rem;
}

.buttons-filter {
  border-radius: 13px;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0b184a;
  color: #fff;
}
@media (max-width: 1199px) {
  .section-header-4 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: 0 !important;
  }
}
</style>
